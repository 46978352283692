import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1173.000000 1280.000000" >
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)">


<path d="M6415 8700 c-428 -77 -925 -315 -1393 -667 l-83 -63 -642 0 c-549 0
-652 -2 -702 -16 -154 -40 -269 -162 -304 -322 -7 -36 -11 -252 -11 -690 0
-694 2 -712 57 -815 34 -62 117 -138 191 -174 l57 -28 1580 0 1580 0 40 22
c91 49 161 140 195 252 18 57 20 93 20 367 0 282 -2 309 -21 366 -38 115 -117
209 -208 252 -44 20 -64 21 -566 26 l-520 5 0 180 0 180 250 8 c138 4 433 10
658 12 l407 5 0 51 c0 102 -67 240 -151 313 -88 75 -49 71 -684 71 -561 0
-571 0 -615 -21 -59 -28 -138 -109 -173 -178 -25 -50 -35 -85 -53 -201 l-7
-40 -17 55 c-9 30 -27 75 -40 100 -29 58 -123 150 -178 174 -56 25 -47 35 103
106 232 111 427 177 677 230 197 43 359 60 551 60 413 0 696 -92 900 -293 33
-33 56 -47 87 -52 23 -4 44 -4 47 -1 11 10 -19 172 -47 256 -77 230 -225 385
-430 448 -19 6 -51 16 -70 23 -19 6 -111 13 -205 15 -137 3 -191 0 -280 -16z
m-1083 -1265 c-9 -347 -6 -376 50 -488 36 -72 97 -135 163 -168 l50 -24 520
-5 520 -5 3 -181 c2 -154 0 -183 -13 -187 -8 -4 -307 -9 -665 -13 l-650 -7 0
376 0 377 -440 0 -440 0 2 -217 3 -218 218 -3 218 -2 -3 -153 -3 -152 -570 0
-570 0 0 580 0 580 215 2 c120 2 224 7 235 13 15 8 931 20 1145 15 13 0 15
-19 12 -120z"/>
<path d="M8680 8692 c0 -7 13 -42 29 -77 35 -77 64 -181 81 -288 16 -97 8
-333 -15 -437 -45 -209 -153 -457 -294 -680 -84 -132 -241 -345 -314 -425 -10
-11 -55 -62 -100 -112 -124 -140 -376 -386 -547 -533 -41 -36 -77 -67 -80 -70
-3 -3 -45 -39 -95 -80 -49 -41 -95 -79 -101 -85 -7 -5 -65 -51 -130 -102 -66
-50 -139 -107 -164 -126 -258 -201 -838 -596 -1237 -843 -61 -38 -109 -72
-107 -76 11 -18 98 9 262 78 95 41 174 74 176 74 2 0 46 20 97 44 52 24 155
72 229 106 255 119 621 315 845 454 72 45 138 86 147 91 83 49 498 349 549
397 8 7 59 52 115 100 56 48 155 143 221 211 475 492 720 977 740 1467 11 289
-55 537 -213 792 -59 95 -94 140 -94 120z"/>
<path d="M1012 3803 l3 -778 4775 0 4775 0 3 778 2 777 -4780 0 -4780 0 2
-777z m9437 530 c-1 -90 -4 -136 -9 -116 -20 86 -100 173 -176 192 -26 7 -157
11 -333 11 l-291 0 0 -620 0 -620 310 0 c334 0 346 2 416 55 18 13 44 48 58
77 l26 52 0 -112 0 -112 -4665 0 -4665 0 1 248 c1 234 2 243 14 176 25 -137
62 -221 133 -296 74 -79 148 -108 280 -108 103 0 177 24 243 80 51 43 59 47
59 28 0 -7 5 -30 11 -50 l10 -38 79 0 79 0 -2 348 -2 347 -232 3 -233 2 0
-135 0 -135 96 0 c94 0 95 0 89 -22 -9 -32 -36 -68 -68 -92 -42 -31 -134 -27
-179 8 -58 44 -81 110 -86 250 -7 192 18 286 91 340 39 29 140 30 179 1 31
-22 68 -90 68 -123 0 -22 1 -23 137 -20 l137 3 -3 58 c-11 238 -166 404 -394
423 -131 11 -250 -26 -338 -104 -72 -64 -135 -199 -159 -340 -5 -29 -8 53 -9
221 l-1 267 4665 0 4665 0 -1 -147z m-8724 67 c17 -7 36 -14 43 -16 18 -3 83
-47 114 -76 60 -56 111 -172 124 -281 l7 -57 -127 0 c-123 0 -126 1 -126 22 0
40 -41 109 -73 123 -16 7 -24 14 -17 17 7 2 -17 5 -54 6 -87 3 -132 -19 -177
-90 l-34 -53 0 -195 c0 -182 1 -198 22 -236 12 -23 36 -53 52 -67 19 -16 26
-27 18 -30 -13 -5 13 -8 83 -8 64 -1 109 5 103 14 -4 6 12 11 29 8 5 0 7 2 6
7 -2 4 8 24 20 44 14 22 22 49 20 65 l-3 28 -93 3 -93 3 3 112 3 112 218 3
217 2 0 -330 0 -330 -64 0 -65 0 -12 55 c-7 31 -16 58 -20 61 -4 2 -16 -7 -27
-21 -12 -15 -26 -23 -35 -19 -8 3 -18 1 -22 -5 -4 -7 1 -8 12 -4 16 6 16 5 4
-11 -40 -48 -187 -91 -277 -79 -175 21 -274 113 -334 306 -36 116 -48 286 -31
447 32 323 200 497 466 486 50 -2 104 -9 120 -16z m8553 -15 c43 -19 84 -54
76 -67 -3 -5 0 -7 8 -6 7 2 12 -5 12 -14 -1 -9 4 -14 11 -12 7 3 15 -3 18 -13
3 -10 13 -40 21 -66 8 -26 11 -45 6 -42 -4 3 -3 -8 3 -24 14 -38 -5 -165 -33
-220 -20 -41 -58 -76 -91 -86 -16 -5 -18 -10 -8 -21 6 -8 17 -14 25 -14 7 0
16 -5 20 -12 5 -8 2 -9 -10 -5 -10 4 -15 3 -11 -4 4 -5 15 -7 24 -4 21 6 61
-46 87 -115 24 -65 25 -220 1 -283 -11 -26 -23 -45 -28 -42 -5 4 -6 0 -3 -8 8
-21 -51 -84 -99 -107 -38 -19 -64 -20 -349 -20 l-308 0 0 600 0 600 295 0
c244 0 302 -3 333 -15z m172 -542 l-1 -158 -19 37 c-11 20 -37 51 -58 69 l-39
33 29 24 c30 25 78 110 78 137 0 8 2 15 5 15 3 0 5 -71 5 -157z"/>
<path d="M6040 4427 c-158 -46 -255 -157 -306 -352 -15 -57 -19 -108 -19 -275
0 -183 2 -214 23 -288 28 -105 62 -171 115 -229 87 -95 175 -127 327 -121 84
3 105 8 163 36 118 58 183 160 217 337 6 33 10 83 8 110 l-3 50 -110 3 c-149
4 -155 2 -155 -51 0 -48 -27 -113 -58 -140 -28 -26 -86 -37 -127 -26 -75 21
-115 128 -115 315 0 226 48 324 159 324 35 0 49 -6 76 -33 33 -33 55 -88 55
-139 0 -58 1 -58 146 -58 l134 0 0 55 c0 83 -27 201 -63 275 -66 135 -182 210
-335 216 -53 2 -105 -1 -132 -9z m255 -31 c13 -9 30 -16 37 -16 7 0 31 -16 53
-36 22 -20 34 -33 26 -28 -9 5 -12 3 -7 -4 4 -7 13 -12 20 -12 29 0 100 -139
113 -220 2 -14 6 -40 7 -58 2 -18 6 -36 10 -39 3 -3 6 -21 6 -40 l0 -33 -124
0 -124 0 -6 53 c-15 115 -72 177 -163 177 -58 0 -114 -53 -137 -130 -22 -75
-23 -344 -1 -414 8 -28 13 -59 10 -70 -3 -12 -2 -16 1 -9 3 8 17 0 40 -22 41
-41 88 -52 146 -34 56 18 94 73 110 157 l12 62 119 0 120 0 -6 -47 c-15 -123
-29 -183 -57 -241 -32 -67 -82 -126 -95 -114 -5 4 -5 2 -1 -5 8 -15 -51 -56
-114 -78 -59 -21 -200 -22 -267 -2 -62 19 -105 45 -96 60 3 6 1 7 -5 3 -11 -7
-87 53 -78 61 3 3 -4 15 -15 26 -12 13 -17 29 -13 41 4 15 2 17 -4 8 -11 -17
-46 63 -62 145 -6 32 -15 80 -21 106 -23 118 -1 396 39 492 28 67 56 114 65
108 6 -3 7 -1 3 5 -9 15 45 84 61 78 6 -3 17 3 25 12 12 15 11 16 -4 3 -10 -7
-18 -11 -18 -7 0 12 81 56 92 50 6 -4 8 -3 5 3 -8 13 58 27 75 17 7 -5 8 -3 3
6 -12 20 187 7 220 -14z"/>
<path d="M7975 4427 c-184 -48 -275 -180 -275 -396 0 -216 97 -328 354 -406
61 -18 98 -36 118 -55 33 -33 35 -49 9 -81 -15 -19 -30 -24 -83 -27 -58 -4
-67 -2 -96 23 -25 21 -33 37 -38 75 -4 32 -12 50 -21 52 -9 2 -70 2 -137 0
l-122 -3 7 -87 c14 -171 89 -289 212 -332 78 -28 254 -39 325 -20 168 45 262
188 262 402 0 113 -15 173 -59 238 -50 74 -97 104 -246 155 -156 54 -175 63
-185 91 -14 35 -1 61 40 79 72 33 145 -3 156 -77 l7 -38 133 0 134 0 0 38 c0
163 -89 303 -227 356 -62 23 -202 30 -268 13z m283 -42 c102 -46 176 -151 196
-280 10 -62 3 -65 -122 -68 l-112 -3 -11 39 c-21 79 -78 105 -177 82 -35 -8
-62 -61 -50 -96 10 -29 59 -72 93 -82 177 -50 263 -85 248 -101 -4 -4 -1 -5 7
-2 27 10 109 -81 95 -104 -4 -6 -1 -9 6 -8 19 5 48 -99 48 -176 2 -195 -99
-355 -250 -396 -62 -17 -217 -14 -286 6 -67 19 -143 71 -172 117 -43 70 -85
216 -58 201 8 -5 8 -3 0 7 -7 7 -13 27 -13 44 l0 31 96 3 c134 3 149 -1 151
-45 4 -79 66 -120 167 -111 39 4 65 12 76 24 19 19 33 120 19 129 -5 3 -6 -3
-2 -12 5 -14 -2 -12 -28 8 -29 22 -144 68 -171 68 -5 0 -25 7 -46 17 -20 9
-39 17 -42 18 -30 7 -83 46 -117 83 -54 59 -83 141 -89 247 -3 68 0 92 23 160
18 51 33 79 41 77 8 -1 11 2 7 9 -16 25 76 96 167 130 81 30 221 23 306 -16z"/>
<path d="M8196 4137 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 -10 9 -11 8 -5 -6z"/>
<path d="M8433 4075 c-3 -9 -3 -18 -1 -21 3 -3 8 4 11 16 6 23 -1 27 -10 5z"/>
<path d="M2180 3800 l0 -620 374 0 c305 0 375 2 379 14 3 7 3 77 0 154 l-6
142 -231 2 -231 3 -3 78 -3 77 185 0 c101 0 191 3 200 6 14 5 16 28 16 155 l0
149 -200 0 -200 0 0 70 0 70 233 2 232 3 3 134 c2 74 1 145 -3 158 l-5 23
-370 0 -370 0 0 -620z m730 460 l0 -140 -227 -2 -228 -3 -3 -87 -3 -88 195 0
195 0 6 -52 c3 -29 5 -89 3 -133 l-3 -80 -194 -2 c-106 -1 -195 -4 -198 -7 -6
-6 -6 -176 0 -182 3 -3 108 -6 233 -7 l229 -2 3 -137 3 -138 -366 0 -365 0 0
600 0 600 360 0 360 0 0 -140z"/>
<path d="M3070 3800 l0 -620 380 0 380 0 0 155 0 155 -235 0 -235 0 0 80 0 80
193 2 192 3 3 153 3 152 -196 0 -195 0 0 70 0 70 230 0 231 0 -3 158 -3 157
-372 3 -373 2 0 -620z m730 460 l0 -140 -230 0 -230 0 0 -90 0 -90 194 0 c226
0 203 15 206 -132 2 -152 21 -139 -203 -138 l-192 2 0 -97 0 -96 230 -2 230
-2 3 -137 3 -138 -366 0 -365 0 0 600 0 600 360 0 360 0 0 -140z"/>
<path d="M3900 4250 l0 -170 135 0 135 0 0 -450 0 -450 144 0 c122 0 145 2
150 16 3 9 6 211 6 450 l0 434 135 0 135 0 0 170 0 170 -420 0 -420 0 0 -170z
m829 13 c0 -75 -3 -143 -6 -150 -4 -11 -34 -14 -137 -12 l-131 1 -5 -448 -5
-449 -127 -3 -128 -3 -2 452 -3 451 -135 1 -135 2 -3 148 -3 147 411 0 411 0
-2 -137z"/>
<path d="M4850 3800 l0 -620 380 0 380 0 0 155 0 155 -232 2 -233 3 -3 78 -3
77 194 0 c193 0 195 0 201 23 8 29 8 216 0 256 l-6 31 -194 0 -194 0 0 70 0
70 230 0 230 0 0 160 0 160 -375 0 -375 0 0 -620z m731 588 c2 -7 3 -70 1
-140 l-3 -128 -227 -2 -227 -3 0 -85 0 -85 198 -3 197 -2 0 -136 0 -136 -197
3 -198 3 0 -98 0 -98 238 1 237 2 0 -140 0 -141 -365 0 -365 0 0 600 0 600
354 0 c275 0 355 -3 357 -12z"/>
<path d="M6700 3800 l0 -620 145 0 145 0 0 230 0 230 140 0 140 0 0 -230 0
-230 145 0 145 0 0 620 0 620 -145 0 -145 0 0 -225 0 -225 -140 0 -140 0 -2
223 -3 222 -142 3 -143 3 0 -621z m272 379 l3 -221 145 0 c80 1 148 1 152 2 4
0 9 98 10 218 l3 217 128 3 127 3 0 -601 0 -600 -130 0 -130 0 0 219 c0 121
-2 225 -5 233 -4 10 -36 12 -153 8 l-147 -5 -3 -227 -2 -228 -130 0 -130 0 0
600 0 600 130 0 130 0 2 -221z"/>
<path d="M8617 4413 c-11 -10 -8 -728 3 -828 24 -218 121 -358 284 -409 45
-14 80 -17 164 -14 96 3 114 7 174 36 107 52 177 147 214 292 13 53 17 137 21
473 3 224 2 418 -1 432 l-6 25 -139 0 -139 0 -4 -407 c-3 -405 -3 -408 -27
-456 -29 -59 -60 -80 -116 -80 -57 0 -92 24 -118 81 -21 45 -22 63 -25 455
l-4 407 -137 0 c-76 0 -141 -3 -144 -7z m267 -22 c3 -4 6 -186 6 -402 l1 -394
25 -52 c56 -117 200 -120 254 -6 13 26 24 57 25 68 1 11 3 194 4 408 l1 387
130 0 130 0 0 -412 c-1 -233 -5 -432 -11 -458 -40 -185 -129 -292 -282 -340
-52 -16 -195 -14 -255 3 -59 18 -135 64 -127 77 3 5 0 8 -7 5 -7 -2 -24 11
-38 31 -14 19 -30 41 -37 48 -6 7 -24 49 -39 92 l-28 79 -4 438 -3 437 125 0
c69 0 127 -4 130 -9z"/>
<path d="M9933 4144 c-16 -16 -18 -211 -3 -216 5 -1 56 -1 112 2 l102 5 19 37
c23 42 17 113 -11 148 -15 19 -28 21 -107 22 -50 0 -92 4 -95 8 -3 4 -11 2
-17 -6z m211 -46 c25 -36 21 -104 -9 -133 -22 -23 -32 -25 -115 -25 l-90 0 0
90 0 90 99 0 c92 0 101 -2 115 -22z"/>
<path d="M9920 3576 l0 -105 107 -3 c59 -2 116 2 127 7 41 21 58 122 29 174
l-18 31 -122 0 -123 0 0 -104z m226 86 c21 -13 34 -51 34 -94 -1 -72 -11 -78
-139 -78 l-111 0 0 90 0 90 103 0 c56 0 107 -4 113 -8z"/>
</g>
</svg>



		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
